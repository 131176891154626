import { useContext } from "react";

// translations
import { t, Trans } from "@lingui/macro";

// contexts
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// icons
import { AccountIcon } from "../../../../assets/icons/AccountIcon";
import { ArrowLeftIcon } from "../../../../assets/icons/ArrowLeftIcon";
import { BankIcon } from "../../../../assets/icons/BankIcon";
import { CloseIcon } from "../../../../assets/icons/CloseIcon";
import { LockIcon } from "../../../../assets/icons/LockIcon";
import { NumberIcon } from "../../../../assets/icons/NumberIcon";
import { PSUIDIcon } from "../../../../assets/icons/PSUIDIcon";

// components
import { AccountSelector } from "../../../../components/account-selector/AccountSelector";
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";

// types
import type { ValidationType } from "../../../../components/input/Input.types";
import type {
  BankType,
  TransactionFormType,
} from "../../../../context/open-banking-provider/OpenBankingProvider.types";

// styles
import styles from "./PayerAccount.module.scss";

type PayerAccountProps = {
  selectedBank: BankType & { country: string };
  payerFormData: TransactionFormType;
  isDisabledButton: boolean;
  isBusinessAccount: boolean | null;
  isBusinessAccOptions: { label: string; value: boolean }[];
  handleChangeSelectData: (newValue: unknown) => Promise<void>;
  setSelectedBank: React.Dispatch<
    React.SetStateAction<(BankType & { country: string }) | null>
  >;
  onSubmit: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  setIsOpenClosingModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangePayerData: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  clearPayerData: () => void;
  validateFullName: () => ValidationType;
  validateIBAN: () => ValidationType;
};

export function PayerAccount({
  selectedBank,
  payerFormData,
  isDisabledButton,
  isBusinessAccount,
  isBusinessAccOptions,
  handleChangeSelectData,
  setSelectedBank,
  onSubmit,
  setIsOpenClosingModal,
  handleChangePayerData,
  clearPayerData,
  validateFullName,
  validateIBAN,
}: PayerAccountProps) {
  const { i18n } = useContext(localesContext);

  return (
    <form className={styles.form}>
      <CloseIcon
        className={styles.closeIcon}
        width={26}
        height={26}
        color="#FDFDFD"
        onClick={() => setIsOpenClosingModal(true)}
      />
      <div className={styles.formHeader}>
        <div className={styles.formNavigation}>
          <div
            className={styles.backButton}
            onClick={() => {
              clearPayerData();
              setSelectedBank(null);
            }}
          >
            <ArrowLeftIcon />
            <p className={styles.navigationText}>
              <Trans>Back</Trans>
            </p>
          </div>
          <div className={styles.bankInfo}>
            {"logo" in selectedBank.media ? (
              <img src={selectedBank.media.logo} alt={selectedBank.name} />
            ) : null}

            <p className={styles.bankTitle}>{selectedBank.name}</p>
          </div>
        </div>
        <div className={styles.formInfo}>
          <p className={styles.title}>
            <Trans>Payer account</Trans>
          </p>
          <p className={styles.description}>
            {selectedBank.country === "GB" ? (
              <Trans>
                Account number and sort code can be found in your bank app or
                statement. Your bank requires it for the payment initiation.
              </Trans>
            ) : (
              <Trans>
                Bank account number can be found in your bank app or statement.
                Your bank requires it for the payment initiation.
              </Trans>
            )}
          </p>
        </div>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.inputsWrapper}>
          <div className={styles.safeWrapper}>
            <LockIcon />
            <p className={styles.safeText}>
              <Trans>Safe authorisation</Trans>
            </p>
          </div>

          <Input
            variant="admin"
            name="fullName"
            placeholder={t(i18n)`Full Name`}
            value={payerFormData.payerData.fullName}
            prefix={<AccountIcon />}
            onChange={handleChangePayerData}
            validation={validateFullName()}
            className={styles.input}
          />
          {selectedBank.country !== "GB" ? (
            <Input
              variant="admin"
              name="payerIban"
              placeholder={t(i18n)`IBAN`}
              value={payerFormData.payerData.payerIban}
              prefix={<BankIcon />}
              onChange={handleChangePayerData}
              validation={validateIBAN()}
              className={styles.input}
            />
          ) : null}

          {selectedBank.country === "GB" ? (
            <>
              <Input
                variant="admin"
                name="payerAccountNumber"
                placeholder={t(i18n)`Account Number`}
                value={payerFormData.payerData.payerAccountNumber || ""}
                prefix={<BankIcon />}
                onChange={handleChangePayerData}
                className={styles.input}
              />
              <Input
                variant="admin"
                name="payerSortCode"
                placeholder={t(i18n)`Sort code (6-digit bank ID)`}
                value={payerFormData.payerData.payerSortCode || ""}
                prefix={<NumberIcon />}
                onChange={handleChangePayerData}
                className={styles.input}
              />
            </>
          ) : null}

          {selectedBank.isPsuRequired ? (
            <>
              <Input
                variant="admin"
                name="psuId"
                placeholder={t(i18n)`PSU ID`}
                value={payerFormData.payerData.psuId || ""}
                prefix={<PSUIDIcon />}
                onChange={handleChangePayerData}
                className={styles.input}
              />

              <AccountSelector
                name="isBusinesssAcc"
                placeholder={t(i18n)`Is this a business account?`}
                options={isBusinessAccOptions}
                value={isBusinessAccOptions.find(
                  ({ value }) => value === isBusinessAccount
                )}
                className={styles.select}
                onChange={handleChangeSelectData}
              />
            </>
          ) : null}
        </div>
        <Button
          disabled={isDisabledButton}
          onClick={onSubmit}
          color="secondary"
        >
          <Trans>Proceed to payment</Trans>
        </Button>
      </div>
    </form>
  );
}
