import { AxiosError } from "axios";
import { useCallback, useContext, useState } from "react";

// helpers
import { getMembershipTitle } from "../../../helpers/get-membership-title";

// components
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";

// consts
import { PATHS } from "../../../route/route.controls";

// contexts
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";
import { errorContext } from "../../../context/error/ErrorProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";
import { orderContext } from "../../../context/order-provider/OrderProvider";
import { paymentContext } from "../../../context/payement-provider/PaymentProvider";
import { sessionContext } from "../../../context/session-provider/SessionProvider";

// types
import type {
  CourseType,
  VideoType,
} from "../../../context/course-provider/CourseProvider.types";
import type { ErrorResponse } from "../../../context/error/ErrorProvider.types";
import type { MembershipTypesType } from "../../../context/membership-provider/MembershipProvider.types";

// styles
import styles from "./CartModalOrder.module.scss";

//translation
import { t, Trans } from "@lingui/macro";
import { generatePath, useNavigate } from "react-router-dom";

type CartModalOrderProps = {
  total: string;
  courses: CourseType[];
  membership: MembershipTypesType | null;
  videos: VideoType[];
  onCloseModal: () => void;
  openLoginModal?: (type: "auth" | "register" | "register_cart") => void;
};

export function CartModalOrder({
  total,
  courses,
  membership,
  videos,
  onCloseModal,
  openLoginModal,
}: CartModalOrderProps) {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { createOrder } = useContext(orderContext);
  const { checkoutPayment } = useContext(paymentContext);
  const { getRoleFromToken } = useContext(sessionContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const navigate = useNavigate();

  const role = getRoleFromToken();

  const [isChecked, setIsChecked] = useState(false);

  const handleChangeCheckbox = useCallback(() => {
    setIsChecked((prev) => !prev);
  }, [setIsChecked]);

  const handleCheckout = useCallback(
    async (type: "securePayCard" | "openBanking") => {
      try {
        const coursesIds = courses.map((course) => course.id);

        const videosIds = videos.map((video) => video.id);

        const orderData = await createOrder(
          coursesIds,
          videosIds,
          membership ? membership.id : ""
        );

        if (orderData) {
          if (type === "openBanking") {
            const generatedLink = generatePath(PATHS.openBanking, {
              orderId: orderData.id,
            });

            onCloseModal();

            return navigate(generatedLink);
          }

          await checkoutPayment(orderData.id);
        }
      } catch (e) {
        if (openLoginModal && !role) openLoginModal("register_cart");

        if (e instanceof AxiosError) {
          const customError = e.response?.data as ErrorResponse;
          if (customError?.message === "Unauthorized") {
            return error(t(i18n)`Please first log in or register.`);
          }

          return error(e);
        }

        error(e);
      } finally {
        onCloseModal();
      }
    },
    [checkoutPayment, total]
  );

  return (
    <div className={styles.orderWrapper}>
      <div>
        <h6>
          <Trans>Order Summary</Trans>
        </h6>
      </div>
      <div className={styles.infoWrapper}>
        {courses.length
          ? courses.map((course) => (
              <div className={styles.info} key={course.id}>
                <p>
                  <span>
                    <Trans>Course:</Trans>
                  </span>{" "}
                  {course.title}
                </p>
                <p className={styles.price}>
                  {getCurrencySymbol()}{" "}
                  {course.discountPrice ? course.discountPrice : course.price}
                </p>
              </div>
            ))
          : null}

        {membership ? (
          <div className={styles.info}>
            <p>
              <span>
                <Trans>Membership</Trans>
              </span>{" "}
              {getMembershipTitle(membership.name)}
            </p>{" "}
            <p className={styles.price}>
              {getCurrencySymbol()}{" "}
              {membership.discountPrice
                ? membership.discountPrice
                : membership.price}
            </p>
          </div>
        ) : null}

        {videos.length
          ? videos.map((video) => (
              <div className={styles.info} key={video.id}>
                <p>
                  <span>
                    <Trans>Video:</Trans>
                  </span>{" "}
                  {video.title}
                </p>
                <p className={styles.price}>
                  {getCurrencySymbol()}{" "}
                  {video.discountPrice ? video.discountPrice : video.price}
                </p>
              </div>
            ))
          : null}

        <div className={styles.separator} />

        <div className={styles.checkboxWrapper}>
          <Checkbox
            id="terms_and_conditions"
            name="terms"
            value="1"
            checked={isChecked}
            onChange={handleChangeCheckbox}
          />
          <p>
            <span>
              <Trans>I have read and agree with</Trans>
            </span>{" "}
            <a
              href={PATHS.termsAndConditions}
              target="_blank"
              onClick={onCloseModal}
            >
              <Trans>Terms of service</Trans>
            </a>
            <span>,</span>{" "}
            <a
              href={PATHS.privacyPolicy}
              target="_blank"
              onClick={onCloseModal}
            >
              <Trans>Privacy policy</Trans>
            </a>{" "}
            <span>
              <Trans>and</Trans>
            </span>{" "}
            <a
              href={PATHS.subscriptionPolicy}
              target="_blank"
              onClick={onCloseModal}
            >
              <Trans>Subscription policy</Trans>
            </a>
          </p>
        </div>

        <div className={styles.total}>
          <p>
            <Trans>Total</Trans>
          </p>
          <p>
            {getCurrencySymbol()} {total}
          </p>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            onClick={() => handleCheckout("securePayCard")}
            disabled={!isChecked}
          >
            <Trans>Secure Checkout</Trans>
          </Button>
          <Button
            onClick={() => handleCheckout("openBanking")}
            disabled={!isChecked}
          >
            <Trans>Pay via bank</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
}
