import React, { FunctionComponent, useContext, useMemo, useState } from "react";

// translations
import { t } from "@lingui/macro";

// contexts
import { apiContext } from "../api-provider/ApiProvider";
import { errorContext } from "../error/ErrorProvider";
import { localesContext } from "../local-provider/LocalProvider";

// helpers
import { handleErrorWithMessages } from "../error/error";

// consts
import {
  API_URL_OPEN_BANKING,
  API_URL_OPEN_BANKING_BANKS,
  API_URL_OPEN_BANKING_COUNTRIES,
  API_URL_OPEN_BANKING_REFERENCE,
  API_URL_OPEN_BANKING_TRANSACTION,
} from "./OpenBankingProvider.consts";

// schemas
import {
  allBanksSchema,
  allCountriesSchema,
  checkTransactionSchema,
  referenceIdSchema,
  transactionSchema,
} from "./OpenBankingProvides.schemas";

// types
import type {
  BanksType,
  CountryType,
  OpenBankingContext,
  OpenBankingProviderProps,
  TransactionFormType,
} from "./OpenBankingProvider.types";

export const openBankingContext = React.createContext({} as OpenBankingContext);

export const OpenBankingProvider: FunctionComponent<
  OpenBankingProviderProps
> = (props) => {
  const { i18n } = useContext(localesContext);
  const { api } = useContext(apiContext);
  const { error } = useContext(errorContext);

  const { children } = props;

  const [countries, setCountries] = useState<CountryType[]>([]);
  const [banks, setBanks] = useState<BanksType>({});

  const fakeCountry = { name: "Fake (Sandbox)", short_code: "XF" };

  const getCountries = async () => {
    try {
      const allCountries = await api(
        API_URL_OPEN_BANKING_COUNTRIES,
        {},
        allCountriesSchema,
      );

      if (allCountries) {
        const updatedCountries = [...allCountries, fakeCountry];
        setCountries(updatedCountries);
        return updatedCountries;
      }

      return [];
    } catch (error) {
      throw error;
    }
  };

  const getBanks = async () => {
    try {
      const allBanks = await api(
        API_URL_OPEN_BANKING_BANKS,
        {},
        allBanksSchema,
      );

      if (allBanks) {
        setBanks(allBanks);
        return allBanks;
      }

      return {};
    } catch (error) {
      throw error;
    }
  };

  const getReferenceId = async (orderId: string) => {
    try {
      const response = await api(
        `${API_URL_OPEN_BANKING_REFERENCE}/${orderId.replace(/['"]/g, "")}`,
        {},
        referenceIdSchema,
      );

      if (response) {
        return response.referenceId;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const createTransaction = async (formData: TransactionFormType) => {
    try {
      const transactionResponse = await api(
        API_URL_OPEN_BANKING_TRANSACTION,
        {
          method: "POST",
          data: formData,
        },
        transactionSchema,
      );

      if (transactionResponse && transactionResponse.acsUrl) {
        return transactionResponse;
      } else {
        error(t(i18n)`Oops. Something went wrong. Try again in a few minutes.`);
        return null;
      }
    } catch (error) {
      throw handleErrorWithMessages(error, {
        "Payment has already been saved": t(
          i18n,
        )`Your payment has been processed. If you want to make changes, please create a new order through the cart.`,
        "Request failed with status code 400": t(
          i18n,
        )`Oops. Something went wrong. Try again in a few minutes.`,
      });
    }
  };

  const checkTheTransaction = async (
    transactionId: string,
    providerPaymentId: string,
  ) => {
    try {
      const checkTheTransactionRespopnse = await api(
        `${API_URL_OPEN_BANKING}/${transactionId}/${providerPaymentId}`,
        {},
        checkTransactionSchema,
      );

      if (checkTheTransactionRespopnse) {
        window.location.href = checkTheTransactionRespopnse.url;
      }
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      countries,
      banks,
      getCountries,
      getBanks,
      getReferenceId,
      createTransaction,
      checkTheTransaction,
    }),
    [
      countries,
      banks,
      getCountries,
      getBanks,
      getReferenceId,
      createTransaction,
      checkTheTransaction,
    ],
  );

  return (
    <openBankingContext.Provider value={contextValue}>
      {children}
    </openBankingContext.Provider>
  );
};
