import { ReactNode } from "react";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";

// components
import Button from "../button/Button";
import Modal from "../modal/Modal";

// styles
import styles from "./ConfirmModal.module.scss";

//translation
import { Trans } from "@lingui/macro";

type ConfirmModalProps = {
  isOpen: boolean;
  modalTitle: string;
  modalText: ReactNode;
  applyButtonText?: string;
  declinedButtonText?: string;
  onClose: () => void;
  onSubmit: () => void;
};

export function ConfirmModal({
  isOpen,
  modalTitle,
  modalText,
  applyButtonText,
  declinedButtonText,
  onClose,
  onSubmit,
}: ConfirmModalProps) {
  const { width } = useWindowSize();

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      header={""}
      width={width > 767 ? "500px" : "90%"}
      className={styles.modal}
    >
      <form className={styles.form}>
        <div className={styles.textWrapper}>
          <p className={styles.title}>{modalTitle}</p>
          <p className={styles.text}>{modalText}</p>
        </div>
        <div className={styles.buttonswWrapper}>
          <Button
            onClick={onSubmit}
            type="submit"
            variant="outline"
            className={styles.removingButton}
          >
            {applyButtonText ? applyButtonText : <Trans>Yes, delete</Trans>}
          </Button>

          <Button onClick={onClose}>
            {declinedButtonText ? declinedButtonText : <Trans>No</Trans>}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
