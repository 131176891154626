export const PATHS = {
  // general
  index: "/",
  membership: "/membership",
  courseOverview: "/course-overview",
  mobileCourses: "/courses",
  course: "/courses/:courseId",
  blog: "/blog",
  aboutUs: "/about-us",
  currentBlog: "/blog/:blogId",
  paymentFailed: "/payment/failed",
  paymentSuccess: "/payment/success",
  cookie: "/cookie",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  refundPolicy: "/refund-policy",
  subscriptionPolicy: "/subscription-policy",
  reset: "/reset",

  openBanking: "/open-banking/:orderId",
  transactionLoading: "transaction/ob/:transactionId",
  failed: "/failed",

  // guest
  authorization: "/authorization/",

  // user
  myCourses: "/user/my-courses",

  // admin
  adminMemberships: "/admin/memberships",
  adminBlog: "/admin/blog",
  adminUsers: "/admin/users",
  adminCourses: "/admin/courses",
  profile: "/user/profile",
  courses: "/admin/courses",
  addCourse: "/admin/courses/form",
  editCourse: "/admin/courses/form/:courseId",
  users: "/admin/users",
  coupons: "/admin/coupons",
} as const;

export const routeControlsList: Array<string> = Object.keys(PATHS);
