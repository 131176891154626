import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// contexts
import { errorContext } from "../../../../context/error/ErrorProvider";
import { openBankingContext } from "../../../../context/open-banking-provider/OpenBankingProvider";

// types
import { BankType } from "../../../../context/open-banking-provider/OpenBankingProvider.types";

export function useBankSelectingForm() {
  const { error } = useContext(errorContext);
  const { countries, banks } = useContext(openBankingContext);

  const [countryFormData, setCountryFormData] = useState<string>("");
  const [banksForSelectedCountry, setBanksForSelectedCountry] = useState<
    BankType[]
  >([]);

  const handleChangeCountryData = useCallback(
    async (newValue: unknown) => {
      try {
        // TODO  Fix the react select types and remove this "as"
        const option = newValue as { label: string; value: string };

        setCountryFormData(option.value);

        setBanksForSelectedCountry(banks[option.value]);
      } catch (e) {
        error(e);
      }
    },
    [setCountryFormData, error, banks]
  );

  const countriesOptions = useMemo(() => {
    if (countries && banks) {
      const bankCountryCodes = Object.keys(banks);

      return countries
        .filter((country) => bankCountryCodes.includes(country.short_code))
        .map((country) => ({
          label: country.name,
          value: country.short_code,
        }));
    }

    return [];
  }, [countries, banks]);

  useEffect(() => {
    if (!countryFormData && countriesOptions.length) {
      setCountryFormData(countriesOptions[0].value);
      setBanksForSelectedCountry(banks[countriesOptions[0].value]);
    }
  }, [countryFormData, banks, countriesOptions]);

  return {
    countryFormData,
    countriesOptions,
    banksForSelectedCountry,
    handleChangeCountryData,
  };
}
