// images
import secondary_arrow_src from "../../../../assets/images/secondary_arrow_background.png";

// hooks
import { useLaterNewsForm } from "./use-later-news";

// context
import { useContext } from "react";
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// components
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";
import { BlogItem } from "../blog-item/BlogItem";

// types
import type { BlogType } from "../../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./LaterNews.module.scss";

//translation
import { t, Trans } from "@lingui/macro";

type LaterNewsProps = {
  laterNewsData: BlogType[];
};

export function LaterNews({ laterNewsData }: LaterNewsProps) {
  const { i18n } = useContext(localesContext);
  const { searchBlogValue, handleChangeHeaderData, searchFilteredArticles } =
    useLaterNewsForm();

  return (
    <>
      <section className={styles.newsSection}>
        <div className={styles.contentWrapper}>
          <div className={styles.titleWrapper}>
            <h3 className={styles.title}>
              <Trans>Later news</Trans>
            </h3>
            <div className={styles.searchWrapper}>
              <Input
                variant="search"
                value={searchBlogValue}
                onChange={handleChangeHeaderData}
                placeholder={t(i18n)`Search`}
                className={styles.input}
              />
              <Button width="auto" onClick={searchFilteredArticles}>
                <Trans>Search</Trans>
              </Button>
            </div>
          </div>

          <div className={styles.mainContent}>
            {laterNewsData && laterNewsData.length ? (
              laterNewsData.map((blog) => (
                <BlogItem key={blog.id} blog={blog} />
              ))
            ) : (
              <h5>
                <Trans>
                  Unfortunately, your search did not return any results. Please
                  try adjusting your search terms and try again.
                </Trans>
              </h5>
            )}
          </div>
        </div>
      </section>
      {laterNewsData && laterNewsData.length ? (
        <img
          src={secondary_arrow_src}
          alt="arrow"
          className={styles.backgroundImage}
        />
      ) : null}
    </>
  );
}
