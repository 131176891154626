import { useContext } from "react";
import { useNavigate } from "react-router-dom";

//consts
import { PATHS } from "../../../route/route.controls";

// images
import arrow_image from "../../../assets/images/arrow_middle_background.png";

//icons
import { ArrowLeftIcon } from "../../../assets/icons/ArrowLeftIcon";

// hooks
import { useWindowSize } from "../../../hooks/use-window-size/use-window-size";
import { useCurrentBlogFetch } from "./use-current-blog";

// components
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";

// context
import { blogContext } from "../../../context/blog-provider/BlogProvider";

// styles
import styles from "./CurrentBlog.screen.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function CurrentBlogScreen() {
  const navigate = useNavigate();

  const { blogById } = useContext(blogContext);

  const { width } = useWindowSize();

  const { isBlogLoading } = useCurrentBlogFetch();

  return (
    <section className={styles.currentBlogSection}>
      {isBlogLoading ? (
        <div className={styles.blogLoader}>
          <Spinner size="large" />
        </div>
      ) : blogById ? (
        <>
          <div className={styles.blogHeader}>
            <div className={styles.imageWrapper}>
              <div className={styles.image}>
                <img src={blogById.picture} alt={blogById.title} />
              </div>
              <div className={styles.backBlock}></div>
            </div>

            <div className={styles.backContainer}>
              <Button
                width="auto"
                icon={<ArrowLeftIcon />}
                iconOrientation="start"
                className={styles.backButton}
                onClick={() => navigate(PATHS.blog)}
              >
                <Trans>All articles</Trans>
              </Button>
            </div>

            <div className={styles.titleContainer}>
              <div className={styles.blogWrapper}>
                <p className={styles.title}>{blogById.title}</p>
              </div>
            </div>
          </div>

          <div className={styles.mainContent}>
            <img
              src={arrow_image}
              alt="Arrow"
              className={styles.topBackgroundImage}
            />
            {width >= 1024 ? (
              <img
                src={arrow_image}
                alt="Arrow"
                className={styles.bottomBackgroundImage}
              />
            ) : null}

            <div
              className={styles.mainText}
              dangerouslySetInnerHTML={{
                __html: blogById.content || "",
              }}
            />
          </div>
        </>
      ) : (
        <div className={styles.noOneArticlesBlock}>
          <h4>
            <Trans>
              Unfortunately, there is no data available for this article.
            </Trans>
          </h4>
        </div>
      )}
    </section>
  );
}
